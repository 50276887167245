<template>
	<div
		id="blog-sidebar"
		class="w-full pb-4 lg:w-1/3 lg:mt-4 lg:pt-4"
	>
		<div class="sticky grid w-full grid-cols-1 px-8 justify-content-center top-16">
			<WwAdvertisement
				:zone="10"
				class="my-4"
				:ad-index="0"
				:number-of-ads-on-page="2"
			/>

			<NewsletterForm
				class="w-full max-w-sm mx-auto my-4"
			/>

			<WwAdvertisement
				:zone="10"
				class="my-4"
				:ad-index="1"
				:number-of-ads-on-page="2"
			/>
		</div>
	</div>
</template>

<script async>
export default {
	components: {
		NewsletterForm: () => import('@/components/multiUse/NewsletterForm.vue'),
		WwAdvertisement: () => import('@/components/UI/WwAdvertisement.vue')
	}
}
</script>
